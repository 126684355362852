import React from "react";
// Customizable Area Start
import Link from '@material-ui/core/Link';
import UserProfileWebController, { Props } from "./UserProfileController.web";
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import { Box, Typography, Button, IconButton,Modal,styled,TextField,Divider, FormControl, Select, Grid, MenuItem, } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { groupReImg, infoReImg, morebtnuploadImg, uploadImg, vectorImg } from "./assets";
import {  MoreVert } from "@material-ui/icons";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json')
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";


const StyledModal = styled(Modal) ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})
const ModalContent = styled(Box)({
    position: 'absolute',
    borderRadius: '20px',
    boxShadow: "0px 8px 32px 0px #0000000F",
    background: "#FFFFFF",
    width: '887px',
    maxHeight: '90vh', 
    overflowX: 'auto',
    overflowY: 'auto'
})
const ModalHeading = styled(Typography)({
    color:"#0F172A",
    fontWeight:700,
    fontSize:"24px",
    letterSpacing:"-0.5%"
})
const ModalHeadingWrapper = styled(Box)({
    padding:"0px 40px",
    height:"80px",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderBottom:"1px solid #E2E8F0"
})
const InputWrapper = styled(Box)({
    padding:"0px 40px",
    width:"792px",
    display:"flex",
    flexDirection:"column",
    gap:"24px",
})
const CustomTextField = withStyles({
    root: {
        width: '380px',
        height: '44px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        color: "#0F172A",
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);
const InputFieldWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: "380px",

})
const InputLabeltext = styled(Typography)({
    fontSize: '14px', 
    fontWeight: 700, 
    color: '#334155', 
    lineHeight: "22px"
})
const FilterSelectField = styled(Select)({
    width: '380px',
    fontSize: "14px",
    height: '44px',
    fontWeight: 400,
    color: "#0F172A",
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent",
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    '& .MuiSelect-select': {
        color: '#94A3B8',
    },
    '&.Mui-focused .MuiSelect-select': {
        color: 'inherit',
    },
});
const FirstWrapperBox = styled(Box)({
    display:"flex",
    gap:"32px"
})
const TextFieldWrapper = styled(Box)({
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

})
const CustomDescriptionField = withStyles({
    root: {
        borderRadius: '8px',
        width: '100%',
        height: '90px',
        color: "#0F172A",
        gap: '8px',
        border: '1px solid #CBD5E1',
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);
const PaperBox = styled(Paper)({
    padding: '24px 64px 24px 64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed grey',
    marginTop:"4px"

});
const UploadDocText = styled(Typography)({
   color:"#0F172A",
   fontSize:"16px",
   fontWeight:700,

});
const FileType = styled(Typography)({
    fontSize: '12px',
    fontWeight: 400,
    color: '#64748B',
    textAlign: 'center'

});
const ChoseFileButton = styled(Button)({
    backgroundColor: '#EFF6FF',
    fontSize: '16px',
    fontWeight: 700,
    color: '#0F172A',
    textTransform: 'none',
    height: '44px',
    marginTop: '8px',
    borderRadius: '8px'

});
const AddMoreDoc = styled(Box)({
    display: 'flex',
    justifyContent: 'center',
    marginTop: '12px',

});
const AddMoreButton = styled(Button)({
    backgroundColor: '#EFF6FF',
    borderRadius: '8px',
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "10px 16px",

});
const UploadImaheBox = styled(Box)({
    height:"24px",
    width:"24px",
    marginTop:"4px"

});
const DocumentButtonText = styled(Typography)({
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 700,
    textTransform: 'none',
    whiteSpace: "nowrap",
    marginLeft:"8px"

});
const StyledDivider = styled(Divider)({
    background: '#CBD5E1',
});
const ButtonWrraper = styled(Box)({
    height: "104px",
    display: "flex",
    gap: '12px',
    alignItems:"center",
    position:'sticky',
    bottom:"0px",
    background:"#FFFFFF",
    padding:"0 24px",
    boxShadow: "0px -3px 8px #00000017",
    marginTop:"24px",
});
const ApproveButton = styled(Box)({
    height: "56px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#1E3A8A',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'none'

});
const RejectButton = styled(Box)({
    width: "100%",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#EFF6FF',
    fontSize: '16px',
    fontWeight: 700,
    color: '#0F172A',
    textTransform: 'none'

});
const MainHeading = styled(Typography)({
    fontSize: "30px", 
    color: "#0F172A", 
    fontWeight: 700, 
    letterSpacing: "-0.5%", 
    marginTop: "25px" 

});
const BusinessHeader = styled(Box)({
    height: "62px", 
    display: "flex", 
    flexDirection: "column", 
    gap: "8px", 
    marginTop: "25px" 

});
const BusinessHeaderWrapper = styled(Box)({
    display: "flex", 
    gap: "12px" ,
    alignItems:"center",

});
const IdText = styled(Typography)({
    fontSize: "12px",
    fontWeight: 400,
    color: "#1E293B"

});
const Status = styled(Typography)({
    fontSize: "12px", 
    fontWeight: 700, 
    textTransform: "uppercase", 
    borderRadius: "40px", 
    padding:"2px 6px 2px 8px", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center" 

});
const ApexHead = styled(Typography)({
    fontSize: "24px", 
    fontWeight: 600, 
    letterSpacing: "-0.5%", 
    color: "#1E293B"

});
const TimelineBox = styled(Box)({
    padding: '40px', 
    display: 'flex', 
    flexDirection: 'column', 
    gap: '18px', 
    height: 'auto', 
    width: '793px', 
    borderRadius: '24px', 
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px'

});
const TimelineHeading = styled(Typography)({
    fontSize: '20px', 
    fontWeight: 600, 
    color: '#1E293B', 
    marginLeft: "16px"

});
const TimelineWrapper = styled(Box)({
    display: 'flex', 
    alignItems: 'center', 
    marginBottom: '40px', 
    position: 'relative', 
    paddingLeft: '40px'

});
const TimelineCount = styled(Box)({
    padding: '8px', 
    backgroundColor: '#EFF6FF', 
    color: '#1D4ED8', 
    borderRadius: '50%', 
    width: '24px', 
    height: '24px', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    position: 'absolute', 
    left: '8px', 
    top: '0px', 
    fontSize: '18px', 
    fontWeight: 600,

});
const TimelineTextBox = styled(Box)({
    marginLeft: '20px', 
    display: 'flex', 
    flexDirection: 'column', 
    gap: '8px'

});
const TimelineDate = styled(Typography)({
    fontSize: '12px', 
    color: '#475569',
    fontWeight: 400, 

});
const TimelineText = styled(Typography)({
    color: '#1E293B',
    fontSize: '16px', 
    fontWeight: 500, 

});
const FileBox = styled(Box)({
    display: 'flex', 
    gap: '16px' 
});
const FileBoxWrapper = styled(Box)({
    display: 'flex', 
    alignItems: 'center', 
    gap: '16px', 
    marginBottom: '10px', 
    padding: '8px 4px 8px 8px', 
    borderRadius: '4px', 
    backgroundColor: '#f5f5f5'
});
const FileTextWrapper = styled(Box)({
    display: 'flex', 
    flexDirection: 'column'
});
const FileName = styled(Typography)({
    fontSize: '16px', 
    fontWeight: 400, 
    color: '#0F172A'
});
const FileSize = styled(Typography)({
    fontSize: '14px', 
    fontWeight: 400, 
    color: '#64748B'
});
const RiskBox = styled(Typography)({
    display: 'flex', 
    alignItems: 'center', 
    color: '#059669', 
    fontSize: '16px', 
    fontWeight: 500,
    gap:"8px"
});
const DealButtonBox = styled(Box)({
    marginLeft: '20px', 
    textAlign: 'center'
});
const CreateDealButton = styled(Button)({
    background: '#1E3A8A', 
    fontSize: '14px', 
    fontWeight: 700, 
    textTransform: 'none', 
    color: "#FFFFFF",
    '&:hover': {
        background: '#1E3A8A',   
      },
});
const InputFieldWrapperBox = styled(Box)({
    display: "flex", 
    gap: "24px", 
    flexDirection: "column", 
    marginTop: "24px"
});

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    labelText: {
        fontSize: "12px",
        color: "#fff",
        margin: "0px",
        fontWeight: 700,
        display: "flex"
    },
    container: {
        justifyContent: "center",
        gap: '20px',
        alignItems: "center",
        display: 'flex',
        flexDirection: "column",
    },
    mainBoxConatiner: {
        padding: "50px 100px",
        width: "100%",
        borderRadius: "20px",
        backgroundColor: "#1E293B",
        marginBottom: "32px"
    },
    lableTextBox: {
        fontFamily: "poppins",
        fontWeight: 600,
        fontSize: "24px",
        marginBottom: "25px",
        color: "#fff",
    },
    gridMainContainer: {
        alignItems: "center",
        gap: '20px',
        justifyContent: "start",
        display: 'flex',
    },
    formControllerBox: {
        margin: "12px 0px",
        width: '100%'
    },
    reqireText: {
        color: "red"
    },
    inputTextFiled: {
        border: '1px solid var(--neutrals-cool-gray-300, #CBD5E1)',
        background: 'var(--basic-white, #FFF)',
        width: "100%",
        borderRadius: "8px",
        height: "44px",
        fontFamily: "Inter"
    },
    documnetIcon: {
        backgroundColor: "#C2D5FF",
        marginLeft: "16px",
        height: "56px",
        width: "56px",
        justifyContent: "center",
        borderRadius: "8px",
        alignItems: "center",
        display: "flex",
    },
    documentMainBox: {
        padding: "16px 0",
        backgroundColor: "#fff",
        marginTop: "5px",
        borderRadius: "8px",
        gap: "16px",
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
    documnetName: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#0F172A",
        fontFamily: "inter",
    },
    documnetSizeText: {
        color: "#64748B",
        fontFamily: "inter",
        fontSize: "16px",
        fontWeight: 400,
    },
    optionBox: {
        marginRight: "16px",
        marginLeft: "auto"
    },
    activeTab: {
        color: "#0F172A",
        fontWeight: 700
    },
    mainHeadBoxRe: {
        height: "30px",
        width: "264px",
        display: "flex",
        gap: "10px",
        alignItems: "center"
    },
    headBoxText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#475569",
    },
    headBoxText2: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#475569",
    },
    reportLine : {
        position: 'relative' as 'relative',
        margin: '20px 0',
        padding: '0px 0px',
        '&::before': {
          content: '""',
          position: 'absolute' as 'absolute',
          left: '28px',
          top: 0,
          bottom: 0,
          width: '1px',
          backgroundColor: '#E2E8F0',
        },
      },
      requestBredcrum: {
        height: "9.31px", 
        width: "5.49px", 
        color: "#64748B"
      },
      timelineImage: {
        marginRight: '10px',
        height:"56px",
        width:"56px"
      },
      riskImage: {
        height:"20px",
        width:"20px"
      },
      uploadBoxImage: {
        height: "15.57px", 
        width: "16px"
      }
  
    

  
    // Customizable Area End
}


export class RequetWeb extends UserProfileWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 
    renderUploadBox = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <Grid item xs={12} md={6}>
                <InputLabeltext>{languageData.Document}</InputLabeltext>
                <PaperBox elevation={1}>
                    <IconButton color="primary" component="span" style={{ fontSize: 40 }}>
                        <img src={uploadImg} style={{height:"20.77px",width:"21.33px"}}/>
                    </IconButton>
                    <UploadDocText>
                        {languageData.Uploaddocument}
                    </UploadDocText>
                    <FileType gutterBottom>
                        {languageData.DocType1} <br />{languageData.DocType2}
                    </FileType>
                    <ChoseFileButton variant='text'>
                        {languageData.ChooseFile}
                        <input type="file" hidden />
                    </ChoseFileButton>
                </PaperBox>
            </Grid>
        )
    }

    renderFileBox = () => {
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <FileBoxWrapper>
            <img className={classes.timelineImage} src={groupReImg} alt="group"/>
            <FileTextWrapper>
                <FileName>{languageData.FileName}</FileName>
                <FileSize>{languageData.Size}</FileSize>
            </FileTextWrapper>
            <Box style={{ marginLeft: 'auto' }}>
                <IconButton size="small">
                    <MoreVert />
                </IconButton>
            </Box>
        </FileBoxWrapper>
        )
    }
    
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];

        const breadcrumbs = [
            <Link underline="hover" key="1" color="inherit">
                Profile
            </Link>,
            <Link underline="hover" key="1" color="inherit" href="/UserProfileWeb">
                Requests
            </Link>,
            <Link
                underline="hover"
                key="2"
                className={classes.activeTab}
            >
                {"Requests 1"}
            </Link>,
        ];


        return (
            <UserProfileDrawer
                data-test-id="drawerComponentTestID"
                handleClick={this.handleDrawerClick}
                pageName="UserRequests">
                <Box>
                    <Box className={classes.mainHeadBoxRe}>
                        <Typography onClick={() => this.props.navigation.navigate('UserRequests')} className={classes.headBoxText}>{languageData.VerificationRequest}</Typography>
                        <img className={classes.requestBredcrum} src={vectorImg} alt='icon'/>
                        <Typography className={classes.headBoxText2}>{languageData.DetailsPage}</Typography>
                    </Box>
                    <MainHeading>{languageData.DetailVerificationRequest}</MainHeading>
                    <BusinessHeader>
                        <BusinessHeaderWrapper>
                            <IdText>#{this.state.data.id}</IdText>
                            <Status
                            style={{
                                
                                  ...this.handleStatusColor(this.state.data.attributes.status)
                            }}>

                            {this.state.data.attributes.status}
                            </Status>
                        </BusinessHeaderWrapper>
                        <ApexHead>{this.state.data.attributes.full_name}</ApexHead>
                    </BusinessHeader>

                    <TimelineBox>
                        <TimelineHeading>{languageData.VerificationTimeline}</TimelineHeading>
                        <Box className={classes.reportLine}>
                        {this.state.data.attributes.timelines && this.state.data.attributes.timelines.map((item, index) => (
                            <TimelineWrapper key={index}>
                                <TimelineCount>{index + 1}</TimelineCount>
                                <TimelineTextBox>
                                    <TimelineDate>{this.formatTimelineDate(item.created_at)}</TimelineDate>
                                    <TimelineText>{item.desc}</TimelineText>
                                </TimelineTextBox>
                            </TimelineWrapper>
                        ))}

                            <TimelineWrapper>
                                <TimelineCount>{this.state.data.attributes.timelines.length + 1}</TimelineCount>
                                <TimelineTextBox>
                                    <TimelineDate>26 Feb 2022</TimelineDate>
                                    <FileBox>
                                        {this.renderFileBox()}
                                        {this.renderFileBox()}
                                    </FileBox>
                                    <RiskBox>
                                       <img className={classes.riskImage} src={infoReImg} alt="info"/>  
                                       {languageData.LowRiskBusiness}
                                    </RiskBox>
                                </TimelineTextBox>
                            </TimelineWrapper>

                            <TimelineWrapper>
                                <TimelineCount>{this.state.data.attributes.timelines.length + 2}</TimelineCount>
                                <DealButtonBox>
                                    <CreateDealButton onClick={this.handleModalOpen} variant="contained">{languageData.CreateBusinessDeal}</CreateDealButton>
                                </DealButtonBox>
                            </TimelineWrapper>
                        </Box>
                    </TimelineBox>
                    <StyledModal
                        open={this.state.isModalOpen}
                        onClose={this.handleModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}>
                        <ModalContent>
                            <ModalHeadingWrapper>
                                <ModalHeading>{languageData.BusinessDeal}</ModalHeading>
                                <CloseIcon onClick={this.handleModalClose}/>
                            </ModalHeadingWrapper>
                            <InputWrapper>
                                <FirstWrapperBox>
                                    <InputFieldWrapperBox>
                                        <InputFieldWrapper>
                                            <InputLabeltext>{languageData.ClientName}</InputLabeltext>
                                            <CustomTextField
                                                variant="outlined"
                                                placeholder={languageData.ClientName}
                                                value={this.state.business_deal.name}
                                                onChange={(event) => this.handleInputPostData('clientName', event.target.value)} />
                                        </InputFieldWrapper>
                                        <InputFieldWrapper>
                                            <InputLabeltext>{languageData.SubCategory}</InputLabeltext>
                                            <FormControl fullWidth>
                                                <FilterSelectField
                                                    fullWidth
                                                    data-test-id="listTypeTestID"
                                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                                    variant="outlined"
                                                    name="subCategory"
                                                    defaultValue={"option 1"}
                                                    value={this.state.business_deal.sub_category_id}
                                                    onChange={(event) => this.handleInputPostData('subCategory', event.target.value as string)}>
                                                    <MenuItem value={"option 1"}>Credit Card</MenuItem>
                                                    <MenuItem value={"option 2"}>Debit Card</MenuItem>
                                                </FilterSelectField>
                                            </FormControl>
                                        </InputFieldWrapper>
                                    </InputFieldWrapperBox>
                                    <InputFieldWrapperBox>
                                        <InputFieldWrapper>
                                            <InputLabeltext>{languageData.Category}</InputLabeltext>
                                            <FormControl fullWidth>
                                                <FilterSelectField
                                                    fullWidth
                                                    data-test-id="listTypeTestID"
                                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                                    variant="outlined"
                                                    name="category"
                                                    defaultValue={"option 1"}
                                                    value={this.state.business_deal.category_id}
                                                    onChange={(event) => this.handleInputPostData('category', event.target.value as string)}>
                                                    <MenuItem value={"option 1"}>Goods</MenuItem>
                                                    <MenuItem value={"option 2"}>Bads</MenuItem>
                                                </FilterSelectField>
                                            </FormControl>
                                        </InputFieldWrapper>
                                        <InputFieldWrapper>
                                            <InputLabeltext>{languageData.Quantity}</InputLabeltext>
                                            <CustomTextField
                                                variant="outlined"
                                                placeholder={languageData.Quantity}
                                                value={this.state.business_deal.quantity}
                                                onChange={(event) => this.handleInputPostData('quantity', event.target.value)} />
                                        </InputFieldWrapper>

                                    </InputFieldWrapperBox>
                                </FirstWrapperBox>
                                <TextFieldWrapper>
                                    <InputLabeltext>{languageData.Description}</InputLabeltext>
                                    <CustomDescriptionField
                                        variant="outlined"
                                        placeholder={languageData.Description} 
                                        value={this.state.business_deal.description}
                                        onChange={(event) => this.handleInputPostData('description', event.target.value)}/>
                                </TextFieldWrapper>
                                <FirstWrapperBox>
                                    <Box>
                                        <InputFieldWrapper>
                                            <InputLabeltext>{languageData.PaymentTerms}</InputLabeltext>
                                            <FormControl fullWidth>
                                                <FilterSelectField
                                                    fullWidth
                                                    data-test-id="listTypeTestID"
                                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                                    variant="outlined"
                                                    name="paymentTerms"
                                                    defaultValue={"option 1"}
                                                    value={this.state.business_deal.terms}
                                                    onChange={(event) => this.handleInputPostData('paymentTerms', event.target.value as string)}>
                                                    <MenuItem value={"option 1"}>30% Down payment</MenuItem>
                                                    <MenuItem value={"option 2"}>50% Down payment</MenuItem>
                                                </FilterSelectField>
                                            </FormControl>
                                        </InputFieldWrapper>
                                    </Box>
                                    <Box>
                                        <InputFieldWrapper>
                                            <InputLabeltext>{languageData.TotalPrice}</InputLabeltext>
                                            <CustomTextField
                                                variant="outlined"
                                                placeholder={languageData.TotalPrice}
                                                value={this.state.business_deal.price}
                                                onChange={(event) => this.handleInputPostData('totalPrice', event.target.value)} />
                                        </InputFieldWrapper>

                                    </Box>
                                </FirstWrapperBox>
                                <Grid spacing={4} container>
                                    {this.renderUploadBox()}
                                    {this.renderUploadBox()}

                                </Grid>
                                <AddMoreDoc>
                                    <AddMoreButton variant='text'>
                                        <UploadImaheBox><img src={morebtnuploadImg} className={classes.uploadBoxImage} /></UploadImaheBox>
                                        <DocumentButtonText>{languageData.documentButtonText}</DocumentButtonText>
                                    </AddMoreButton>
                                </AddMoreDoc>

                                <StyledDivider />
                                
                            </InputWrapper>
                            <ButtonWrraper>
                                    <RejectButton onClick={this.handleModalClose}>
                                        {languageData.Cancel}
                                    </RejectButton>
                                    <ApproveButton
                                    onClick={() => {
                                        this.handlePostBusinessDealData();
                                        this.handleModalClose();
                                    }}>
                                        
                                        {languageData.SendBuyerConfirmation}
                                    </ApproveButton>
                                </ButtonWrraper>
                        </ModalContent>
                    </StyledModal>
                </Box>
            </UserProfileDrawer>
        )
    }
}

export default withStyles(styles)(RequetWeb);