import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { bellIcon, editProfile, listIcon, sheetIcon } from "./assets";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json')

interface BusinessDeal {
    name: string;
    description: string;
    price: number;
    category_id: number;
    sub_category_id: number;
    quantity: number;
    terms: string;
    verification_request_id: number;
  }

  type Company = {
    id: number;
    user_name: string | null;
    address: string | null;
    city: string;
    state: string;
    country: string;
    zipcode: string | null;
    business_name: string | null;
    tax_number: string | null;
    kyc_name: string | null;
    kyc_number: string | null;
    kyc_verified: string | null;
  };
  
  type Timeline = {
    id: number;
    desc: string;
    verification_request_id: number;
    created_at: string;
    updated_at: string;
  };
  
  type RequestVerificationDetail = {
    id: number;
    type: string;
    attributes: {
      uuid: string;
      full_name: string;
      status: string;
      registration_number: string;
      verification_type: string;
      request_date: string;
      details: string;
      Company: Company;
      timelines: Timeline[];
    };
  };
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentPage: string,
    breadCrumb: string[],
    menu: {
        icon: string,
        title: string,
        route: string,
        tabs: {
            title: string,
            route: string
        }[]
    }[];
    providerMenu: {
        icon: string,
        title: string,
        route: string,
        tabs: {
            title: string,
            route: string
        }[]
    }[];
    type: string,
    currentLanguage: 'en' | 'ar'
    cancelModal: boolean,
    isModalOpen: boolean,
    isSubmit : boolean,
    business_deal: BusinessDeal;
    data: RequestVerificationDetail;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserProfileWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchRequestCallId:string = ""
    fetchRequestPostCallId:string = ""
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentPage: '',
            type: "consumer",
            breadCrumb: [],
            currentLanguage: 'en',
            menu: this.Menu('en'),
            providerMenu: this.ProvideMenu('en'),
            cancelModal: false,
            isModalOpen: false,
            isSubmit : false,
            business_deal: {
                name: '',
                description: '',
                price: 0,
                category_id: 0,
                sub_category_id: 0,
                quantity: 123,
                terms: '',
                verification_request_id: 29,
              },
              data: {
                "id": 0,
                "type": "",
                "attributes": {
                    "uuid": "",
                    "full_name": "",
                    "status": "",
                    "registration_number": "",
                    "verification_type": "",
                    "request_date": "",
                    "details": "",
                    "Company": {
                        "id": 0,
                        "user_name": null,
                        "address": null,
                        "city": "",
                        "state": "",
                        "country": "",
                        "zipcode": null,
                        "business_name": null,
                        "tax_number": null,
                        "kyc_name": null,
                        "kyc_number": null,
                        "kyc_verified": null
                    },
                    "timelines": [
                        {
                            "id": 0,
                            "desc": "",
                            "verification_request_id": 0,
                            "created_at": "",
                            "updated_at": ""
                        }
                    ],
                }
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
    
            if (apiRequestCallId && responseJson) {
              if (apiRequestCallId === this.fetchRequestCallId) {
                if (!responseJson.errors) {
                  this.fetchRequest(responseJson.data.attributes)
                  this.setState({data : responseJson.data})
                  this.setState({business_deal : responseJson.data})
                }
              }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const currentURL = window.location.pathname;
        this.fetchRequest(await getStorageData("selectedUser"))
        this.setState({
            menu: this.Menu(language),
            providerMenu: this.ProvideMenu(language),
            currentLanguage: language,
            currentPage: currentURL.split("/").map(urlString => urlString.charAt(0).toUpperCase() + urlString.slice(1)).join(""),
            breadCrumb: currentURL.split("/").filter(urlString => (urlString !== "" && urlString !== "User")),
            type: await getStorageData("userType")
        });
        this.handlePostBusinessDealData()
        this.fetchRequest(await getStorageData("selectedRequest"))
        // Customizable Area End
    }

    getArabicTitle = (title: string, currentLanguage: string) => {
        const titleWithoutSpaces = title.replace(/\s+/g, '');
        const languageData = languageTranslationData[currentLanguage];
        switch (titleWithoutSpaces) {
            case "Profile": return languageData.Profile;
            case "Notification": return languageData.Notification;
            case "Requests": return languageData.Requests;
            case "MyListing": return languageData.MyListing;
            case "CreateListing": return languageData.CreateListing;
            case "MyOffers": return languageData.MyOffers;
        }
    }
    // Customizable Area Start
    navigationTrack = [
        {
            title: "Profile",
            link: "UserProfile"
        },
        {
            title: "Notification",
            link: "UserNotification"
        },
        {
            title: "Requests",
            link: "UserRequests"
        },
        {
            title: "My Listing",
            link: "UserMyListing"
        },
        {
            title: "Create Listing",
            link: "UserCreateListing"
        },
        {
            title: "My Offers",
            link: "UserMyOffers"
        }
    ];

    Menu = (language: string) => [
        {
            icon: editProfile,
            tabs: [],
            title: languageTranslationData[language].Profile,
            route: "UserProfile"
        },
        {
            icon: bellIcon,
            tabs: [],
            title: languageTranslationData[language].Notification,
            route: "UserNotification"
        },
        {
            icon: sheetIcon,
            tabs: [],
            title: languageTranslationData[language].Requests,
            route: "UserRequests"
        }
    ]

    ProvideMenu = (language: string) => [
        {
            icon: listIcon,
            title: languageTranslationData[language].MyListing,
            route: "UserMyListing",
            tabs: [
                {
                    title: languageTranslationData[language].CreateListing,
                    route: "UserCreateListing"
                },
                {
                    title: languageTranslationData[language].MyOffers,
                    route: "UserMyOffers"
                }
            ]
        }
    ];

    doExpand = (urlString: string): boolean => {
        return (urlString === "UserMyListing" || urlString === "UserCreateListing" || urlString === "UserMyOffers")
    };

    handleNavigation(route: string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    handleLogout = async () => {
        await removeStorageData("accessToken")
        await removeStorageData("profilePic")
        await removeStorageData("userType")
        this.handleNavigation("Home")
    }

    onLogout = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };
    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.onLogout();
        } else{
            this.handleNavigation(route);
        }
    }
    handleModalClose = () => {
        this.setState({ isModalOpen: false });
    };
    handleModalOpen = () => {
        this.setState({ isModalOpen: true });
    };

    fetchRequest = async (id : number) => {

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchRequestCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.RequestEndPoint}${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token" : await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handlePostBusinessDealData = async () => {
        const jsonData = {
                business_deal: {
                    name: this.state.business_deal.name,
                    description: this.state.business_deal.description,
                    price: this.state.business_deal.price,
                    category_id: 1,
                    sub_category_id: 1,
                    quantity: this.state.business_deal.quantity,
                    terms: this.state.business_deal.terms,
                    verification_request_id: 29
                }
        };

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")

        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchRequestPostCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BusinessDealEndPoint

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(jsonData)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ isSubmit: true });
    }
  
    formatTimelineDate = (dateString: string) => {
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
          return 'Invalid date';
        }
        return new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: '2-digit',
        }).format(date);
      };

      handleInputPostData = (field:string, value: string) => {
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      };

      handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "pending" || value === "due") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected" || value === "past due") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };
      
    // Customizable Area End
}
