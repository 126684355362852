Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.loginAPiEndPoint = "login/login";
exports.loginAPiMethod = "POST";
exports.loginApiContentType = "application/json";

// Customizable Area Start
exports.methodPut = "PUT";
exports.methodDelete = "DELETE"
exports.errorEmailNotValid = "Email not valid.";
exports.errorPasswordNotValid = "Password not valid.";
exports.placeHolderEmail = "Email";
exports.placeHolderPassword = "Password";
exports.labelHeader = "The reason we require login";
exports.btnTxtLogin = "LOG IN";
exports.labelRememberMe = "Remember me";
exports.btnTxtSocialLogin = "LOGIN WITH SOCIAL MEDIA";
exports.labelOr = "OR";
exports.labelTitle = "Log in";
exports.createListApiEndPoint = "bx_block_profile/user_listings"
exports.imgPasswordInVisiblePath = "assets/ic_password_invisible.png";
exports.imgPasswordVisiblePath = "assets/ic_password_visible.png";
exports.loginEndPoint = 'bx_block_login/logins';
exports.superAdminLoginEndPoint="bx_block_login/logins/admin_login"
exports.fetchProfileEndPoint = 'bx_block_profile/profiles/user_profile';
exports.saveProfileEndPoint = 'bx_block_profile/profiles/update_user_profile';
exports.updateProfilePicEndPoint = "bx_block_profile/profiles/update_user_profile_image";
exports.removeProfilePicEndPoint = "bx_block_profile/profiles/destroy_profile_image";
exports.fieldError = "Missing Fields";
exports.acceptdTitleText = "Verification Request Submitted Successfully";
exports.acceptedDescriptionText = 'Your request is under review by the business once they will confirm you will get the documents in the request section of your profile.';
exports.goBackBtn = "Go Back";
exports.rejectedTitleText = "Verification Request Rejected";
exports.rejectedDescriptionText = 'Unfortunately the business rejected your verification request see your remarks to what went wrong.';
exports.commonField = {
    "value": "",
    "error": "",
}
exports.commonFieldSchema = {
    "value": "",
    "error": "",
    "type" : "text",
};
exports.commonFieldSchemaEmail = {
    "value": "",
    "error": "",
    "type" : "email",
};
exports.commonFieldSchemaSelect = {
    "value": "",
    "error": "",
    "type" : "select",
};
exports.dummyResponseProfile = {
    "activated": true,
    "first_name": null,
    "last_name": null,
    "full_phone_number": "917222986579",
    "country_code": "91",
    "email": "tejaswini.provider@gmail.com",
    "phone_number": "7222986579",
    "gender": null,
    "type": "EmailAccount",
    "user_type": "provider",
    "user_name": "Provider",
    "address": null,
    "city": null,
    "state": null,
    "country": null,
    "zipcode": null,
    "business_name": null,
    "tax_number": null,
    "created_at": "2024-02-21T10:37:31.892Z",
    "updated_at": "2024-02-21T11:07:23.046Z",
    "device_id": null,
    "unique_auth_id": "FGt3XO9mkL3vBrVLO2Dxuwtt",
    "images": {
        "business_registration": "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBY1U9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--0534dc487af07ebb0b568ef79d6b3f099139fbca/pexels-photo-674010.jpeg",
        "tax_registration": "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBY1k9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--c0b9517b0b3a985f12805e3804954089ad9365eb/pexels-photo-674010.jpeg",
        "license_certification": "/rails/active_storage/blobs/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBY2M9IiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--fd8157196ccfebc04dac72e2183901ada35b4d87/pexels-photo-674010.jpeg"
    }
};
exports.Seller_User_Type = "provider"
exports.Consumer_User_Type = "consumer"
exports.EN = 'en';
exports.AR = 'ar';
exports.AllRequired = 'All fields are required.';
exports.AllRequiredInArabic = 'جميع الحقول مطلوبة.';
exports.UserListingDetailsEndPoint = "bx_block_profile/user_listings/";
exports.makeOfferEndPoint = "bx_block_profile/user_offers";
exports.myOffersEndPoint = "bx_block_profile/user_offers/fetch_offers_for_debtor";
exports.listRequestVerificationEndPoint = "account_block/request_verifications";
exports.offerActionEndPoint = "bx_block_profile/user_offers/";
exports.updateDetailInfoEndPoint = "account_block/accounts/detailed_information";
exports.deleteAccountEndPoint = "account_block/accounts/remove";
exports.patchApiMethod = "PATCH";
exports.invoiceData = [
    {
        mainID: "#664059",
        invoiceID: "#345",
        payment: "$ 124",
        status: "PAID",
        dueDate: "25 Feb 2022"
    },
    {
        mainID: "#669287",
        invoiceID: "#675",
        payment: "$ 465",
        status: "PAST DUE",
        dueDate: "20 Mar 2021"
    },
    {
        mainID: "#667659",
        invoiceID: "#85",
        payment: "$ 98",
        status: "DUE",
        dueDate: "10 Jun 2019"
    },
    {
        mainID: "#665287",
        invoiceID: "#345",
        payment: "$ 123",
        status: "PAID",
        dueDate: "22 Jan 2024",
    },
    {
        mainID: "#665687",
        invoiceID: "#74",
        payment: "$ 123",
        status: "PAID",
        dueDate: "15 May 2023"
    },
    {
        mainID: "#669049",
        invoiceID: "#2145",
        payment: "$ 88",
        status: "PAID",
        dueDate: "22 Apr 2022",
    }    
];
exports.userDashboardLanguage = {
    "en": {
        "dashboard":"Dashboard",
        "verificationRequest":"Verification Request",
        "search":"Search",
        "requestNewVerification":"Request New Verification",
        "verificationTableHeadings":{
            "requestID":"Request ID",
            "name":"Name",
            "registrationNumber":"Registration Number",
            "date":"Date",
            "status":"Status"
        },
        "results":"1-12 of 48 results",
        "allInvoices":"All Invoices",
        "generateInvoice":"Generate Invoice",
        "invoiceTableHeading":{
            "mainId":"ID",
            "invoiceId":"Invoice ID",
            "payment":"Payment",
            "dueDate":"Due date",
            "status":"Status"
        }
    },
    "ar": {
        "dashboard":"لوحة القيادة",
        "verificationRequest":"طلب التحقق",
        "search":"بحث",
        "requestNewVerification":"طلب التحقق الجديد",
        "verificationTableHeadings":{
            "requestID":"طلب معرف",
            "name":"اسم",
            "registrationNumber":"رقم التسجيل",
            "date":"تاريخ",
            "status":"حالة"
        },
        "results":"1-12 من 48 نتيجة",
        "allInvoices":"جميع الفواتير",
        "generateInvoice":"إنشاء الفاتورة",
        "invoiceTableHeading":{
            "mainId":"بطاقة تعريف",
            "invoiceId":"هوية صوتية",
            "payment":"قسط",
            "dueDate":"تاريخ الاستحقاق",
            "status":"حالة"
        }
    
    }
};
exports.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
exports.upperCaseRegex = /[A-Z]/;
exports.lowerCaseRegex = /[a-z]/;
exports.digitCheckRegex = /\d/;
exports.replaceAlphabetRegex = /\D/g;
exports.formatSupport = "JPG, PNG, PDF, DOCX";
exports.previewValues = {
    time: "",
    portfolioSize: "",
    listingTitle: "",
    averageBalance: "",
    accountCount: "",
    totalNumber: "",
    totalAmount: "",
    totalJudgement: "",
    totalMonth: "",
    pastPay: "",
    monthAvg: "",
    lastCollDay: "",
    lastCollMonth: "",
    lastCollYear: "",
    lastCredDay: "",
    lastCredMonth: "",
    lastCredYear: "",
    typeOfDebt:"",
    ageOfDebt:"",
    recoveryStatus:"",
    supportDocArray: Array(2).fill(null),
    otherDocArray: Array(2).fill(null),
    phoneNumber: "5678901234",
    name: "James Carter",
    email: "james.carter@example.com",
    country: "United State",
    state: "Florida",
    city: "Miami",
    zip: "334556"
}
exports.customBtn = "Request New Verification";
exports.requestId = "Request ID";
exports.name = "Name";
exports.rNumber = "Registration number";
exports.date = "Date";
exports.status = "Status";
exports.formSearch = "Search";
exports.filterBtn = "Filters";
exports.verificationBtn = "Verification Requests";
exports.verificationApprove = "Approved";
exports.verificationApprovenum = "14";
exports.verificationReject = "Rejected";
exports.verificationRejectnum = "5";
exports.verificationPending = "Pending";
exports.verificationPendingnum = "12";
exports.requestVR = "Verification Request";
exports.requestVR2 = "Details Page";
exports.dummyOfferDescription = "Our firm, Apex Financial Solutions, has over 15 years of experience in debt collection and management. We have successfully handled similar debt portfolios and have a proven track record of high recovery rates. Our team’s expertise ensures that we can manage this debt efficiently and effectively, maximizing returns for your business.";
exports.listingDetailsData = "Apex Financial Solutions is a leading provider of comprehensive financial services, specializing in debt collection, financial consultation, and risk management. With over 20 years of industry experience, our mission is to help businesses and individuals navigate their financial challenges and achieve sustainable growth.";
exports.RequestEndPoint= "/account_block/request_verifications/";
exports.BusinessDealEndPoint= "/account_block/business_deals/";
// Customizable Area End