import React, { ReactElement } from 'react';
import { Grid, Paper, Typography, makeStyles, Theme, createStyles, Box } from '@material-ui/core';
import Link from '../../Link';
import { color } from 'react-native-elements/dist/helpers';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: '80px'
    },
    light: {
      backgroundColor: '#fff',
    },

    container: {
      maxWidth: '1200px',
      marginLeft: 'auto',
      marginRight: 'auto',
      "@media (max-width: 767px)": {
        padding: '0px 20px',
      },
    },
    paperGrid: {
      marginBottom: '80px'
    },
    paper: {
      padding: theme.spacing(),
      textAlign: 'left',
      color: theme.palette.text.secondary,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '& div': {
        backgroundColor: 'transparent',
      },
      "@media (max-width: 767px)": {
        textAlign: 'center'
      },
    },
    paperText: {
      color: theme.palette.text.secondary,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      '& div': {
        backgroundColor: 'transparent',
      },
      '& h5': {
        fontSize: '30px',
        fontStyle: 'normal',
        fontWeight: 600,
        color: '#0F172A',
        marginBottom: '10px',
        marginTop: '10px',
        whiteSpace : 'nowrap'
      },
      '& h4': {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        color: '#E2E8F0',
        marginBottom: '24px',
      },
      '& p': {
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        color: '#64748B',
        marginBottom: '15px',
        lineHeigh:'26px',

      }
    },
    paperTextDark: {
      '& h5': {
        color: '#0F172A',
        fontSize: '30px',
        fontStyle: 'normal',
        fontWeight: 600,
        marginBottom: '10px',
        whiteSpace : 'nowrap',
        '@media(max-width:767px)': {
          fontSize: '24px',
        }
      },
      '& h4': {
        color: '#1E293B',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        marginBottom: '24px',
       
      },
      '& p': {
        color: '#64748B',
        fontSize: '18px',
        fontStyle: 'normal',
        fontWeight: 400,
        marginBottom: '15px',
        lineHeigh:'26px',
      }
    },
    paperTextButton: {
      width: '100%',
      marginTop: '40px',
      '& a': {
        backgroundColor: '#F1F5F9',
        padding: '12px 15px',
        borderRadius: '4px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 700,
        color: '#0F172A',
        textDecoration: 'none',
      }
    },
    // Define additional classes for controlling the order
    gridLeftToRight: {
      '& $imageGrid': {
        order: 1, // Image is on the left
      },
      '& $textGrid': {
        order: 2, // Text is on the right
      }
    },
    gridRightToLeft: {
      '& $imageGrid': {
        order: 2, // Image is on the right
        "& .MuiPaper-root": {
          textAlign: 'center',
        }
      },
      '& $textGrid': {
        order: 1, // Text is on the left

      },
      '@media(max-width:991px)': {
        '& $imageGrid': {
          order: 1, // Image is on the left
        },
        '& $textGrid': {
          order: 2, // Text is on the right
        },
      }
    },
    imageGrid: {},
    textGrid: {},
    image: {
      width: "80%",
      "@media (max-width: 960px)": {
        width: "100%",
      },
      "@media (max-width: 600px)": {
        width: "100%",
      },
    }
  })
);


interface GridItemProps {
  image: any;
  topHeading?: string;
  heading: string | ReactElement | undefined ;
  layer?:string;
  subHeading?: string ;
  description: string;
  subDescription?: string;
  darkTheme: boolean;
  alignLeft: boolean;
  button?: { 'buttonText': string, 'module': string };
  handleNavigation?: (module: string) => void;
}

const ImageTextCard: React.FC<GridItemProps> = ({
  image,layer, heading, topHeading, subHeading, description,subDescription, darkTheme, alignLeft, button, handleNavigation }) => {
  const classes = useStyles();
  const gridClass = alignLeft
    ? classes.gridLeftToRight
    : classes.gridRightToLeft;
  const gridId = alignLeft 
    ? "gridLeftToRight"
    : "gridRightToLeft";
  return (
    <div className={darkTheme ? classes.root : classes.light}>
      <div className={classes.container}>
        <Grid
          container
          spacing={2}
          style={{ width: "100%", alignItems: "center" }}
          className={`${gridClass} ${classes.imageGrid} ${classes.textGrid}`}
          id={gridId}
        >
          <Grid item md={6} xs={12} className={classes.imageGrid}>
            <Paper className={classes.paper}>
              <img src={image} alt="Image" width="90%" />
            </Paper>
          </Grid>
          <Grid item md={6} xs={12} className={classes.textGrid}>
            <Box
              className={darkTheme ? classes.paperText : classes.paperTextDark}
            >
              <Typography style={{ color: '#2563EB' }} variant="body1">{topHeading}</Typography>
              {layer && <p style={{ color: '#2563EB' }}>{layer}</p>}
              <Typography variant="h5">{heading}</Typography>
              <Typography variant="h4">{subHeading}</Typography>
              <Typography variant="body1">{description}</Typography>
              {subDescription && <Typography variant="body1">{subDescription}</Typography>}

              {button && (
                <div className={classes.paperTextButton}>
                  <button onClick={() => handleNavigation && handleNavigation(button.module)} >{button.buttonText}</button>
                </div>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default ImageTextCard;
