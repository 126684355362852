export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");

export const imgUserLogin = require('../assets/userLogin.png')
export const imgsellerLogin = require('../assets/sellerLogin.png')
export const siteLightLogo = require('../assets/lightLogo.png')

export const imgQuestion = require('../assets/question.svg')
export const imgSetting = require('../assets/setting.svg')
export const imgSetting2 = require('../assets/setting2.svg')
export const imgContact = require('../assets/contact.svg')
export const imgLocation = require('../assets/location.svg')
export const imgProfile = require('../assets/profile.svg')
export const imgNotes = require('../assets/notes.svg')
export const succesImg = require('../assets/image_verified.png')
export const rejectedImg = require('../assets/image_rejected.png')
export const uploadIcon = require('../assets/upload.png')
export const deleteIcon = require('../assets/delete.png')
export const editIcon = require("../assets/edit.svg")
export const listSummary = require("../assets/listsummary.png")
export const arrowIcon = require("../assets/arrow.svg")
export const arrowIconDark = require("../assets/arrow_dark.svg")
export const calendarIcon = require("../assets/calendar.svg")
export const tickIcon = require("../assets/tick.svg")
export const crossIcon = require("../assets/cross.svg")
export const industry = require("../assets/industry.png")
export const location = require("../assets/location.png")
export const website = require("../assets/website.png")
export const calender = require("../assets/calender.png")
export const image_dollar = require("../assets/image_dollar.png")
export const expandMore = require('../assets/expand_more.png')

export const notificationAvatar = require('../assets/avatar1.svg');
export const bellIcon = require('../assets/bell.svg')
export const editProfile = require('../assets/editProfile.svg')
export const SortIcon = require("../assets/sort.svg")
export const FilterIcon = require("../assets/filter.svg")
export const sheetIcon = require('../assets/sheet.svg')
export const listIcon = require('../assets/list.svg')
export const documentIcon = require('../assets/document.svg');
export const clipIcon = require('../assets/clip.svg');
export const bgImage = require("../assets/loginbg.svg");
// export const offerIcon = require('../assets/offer_icon.svg');
export const viewcircle = require("../assets/viewcircle_.png")
export const image = require("../assets/image_.png")
export const image_notes = require("../assets/image_notes.png")
export const blankBusinessProfile = require("../assets/blankBusinessProfile.svg");
export const fileUploadIcon = require("../assets/fileUploadIcon.svg");
export const groupImg = require("../assets/group.png");
export const verifiedImg = require("../assets/image_verified.png");
export const closeImg = require("../assets/close.png");
export const acceptImg = require("../assets/accept.png");
export const rejectImg = require("../assets/reject.png");
export const pendingImg = require("../assets/pending.png");
export const searchImg = require("../assets/search.png");
export const filterImg = require("../assets/filter.png");
export const deleteRedImg = require("../assets/deleteRed.png");
export const vectorImg = require("../assets/Vector.png");
export const groupReImg = require("../assets/group_16860.png");
export const infoReImg = require("../assets/info.png");
export const blankListingImage = require("../assets/blankListingImage.svg");
export const acitveIcon = require("../assets/active.svg");
export const rejectIcon = require("../assets/rejectIcon.svg");
export const pendingIcon = require("../assets/pendingIcon.svg");
export const visaLogo = require("../assets/visaLogo.svg");
export const masterCardLogo = require("../assets/masterCardLogo.svg");
export const MarkReadIcon = require("../assets/markread.svg")
export const uploadImg = require("../assets/upload.png")
export const morebtnuploadImg = require("../assets/uploadbtn.png")
