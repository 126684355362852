import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData ,removeStorageData,setStorageData} from "framework/src/Utilities";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Request.json')
import { ChangeEvent, MouseEvent } from "react";
const reqVeriData = [
    ["#669677", "Blue Horizon Investments", "APPROVED", "GHI128789", "09 Jul 2018"],
    ["#669459", "Apex Financial Solutions", "APPROVED", "ABC823456", "20 Jan 2018"],
    ["#869287", "Legacy Financial Group", "PENDING", "MNO458723", "17 Sep 2013"],
    ["#664979", "Zenith Credit Management", "REJECTED", "JKL787654", "29 Aug 2023"],
    ["#679287", "Crestwood Capital Partners", "PENDING", "XYZ709012", "12 May 2023"],
    ["#664559", "Silverline Ventures LLC", "APPROVED", "BEF456789", "24 Jun 2012"]
];
  
  export interface VerificationState {
    id: number;
    type: string;
    attributes: {
          uuid: string;
          full_name: string;
          status: string;
          registration_number: string;
          verification_type: string;
          request_date: string;
          details: string;
          Company: string | null;
          Debtor: string | null;
      }
  }
// Customizable Area End
type AllOrNewOrArchived = "All" | "New" | "Archived";
type MyRequestsOrVerificationRequest = "My Requests" | "Verification Request";
interface RequestVerificationAttributes {
    first_name: string;
    last_name: string;
    full_phone_number: string;
    email: string;
    status: string;
    legal_business_name: string;
    uuid: string | null;
    request_date: string | null;
    Company: string | null;
    Debtor: string | null;
}

interface RequestVerificationData {
    id: string;
    type: string;
    attributes: RequestVerificationAttributes;
}

interface ApiResponse {
    data: RequestVerificationData[];
}


export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs: Array<AllOrNewOrArchived>,
    selectedTab: AllOrNewOrArchived,
    MyRequestTab: Array<MyRequestsOrVerificationRequest>,
    MyRequestTabSelectedTab: MyRequestsOrVerificationRequest,
    agreeModal: boolean,
    cancelModal: boolean,
    verificationRequestList: RequestVerificationData[],
    currentLanguage: 'ar' | 'en',
    isFirstModalOpen: boolean,
    isSecondModalOpen: boolean,
    isThirdModalOpen: boolean,
    data: typeof reqVeriData;
    search: string;
    verificationPage: number;
    isFilterDrawerOpen: boolean;
    fromDate: string;
    toDate: string;
    status: string;
    anchorEl: HTMLElement | null | SVGSVGElement
    dot: HTMLElement | null,
    token: string,
    entityName: string;
    registrationNumber: string;
    entityType: string;
    additionalDetails: string;
    isSubmit : boolean
    requesrUserdata: VerificationState[];
    selectedRequest:number;
    totalPages: number;
    currentPage:number;
    rowsPerPage:number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class RequestsWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    verificationRequestListApiCallId: string = "";
    tablePostApiCallId : string = "";
    FilterRequestListApiCallId: string = "";
    archiveUserApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            verificationRequestList: [],
            tabs: this.tabsList('en'),
            selectedTab: "All",
            MyRequestTab: this.myRequestTabList('en'),
            MyRequestTabSelectedTab: "My Requests",
            agreeModal: false,
            cancelModal: false,
            currentLanguage: 'en',
            isFirstModalOpen: false,
            isSecondModalOpen: false,
            isThirdModalOpen: false,
            data: reqVeriData,
            search: "",
            verificationPage: 1,
            isFilterDrawerOpen: false,
            fromDate: '',
            toDate: '',
            status: "approved",
            anchorEl: null,
            dot: null,
            token:"",
            entityName: '',
            registrationNumber: '',
            entityType: 'option 1', 
            additionalDetails: '',
            isSubmit : false,
            requesrUserdata: [],
            selectedRequest: 0,
            totalPages: 1,
            currentPage:1,
            rowsPerPage:5,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors) {
                if (apiRequestCallId === this.verificationRequestListApiCallId) {
                    this.setState({ verificationRequestList: responseJson.data })
                    this.setState({requesrUserdata : responseJson.data})
                }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.getRequestVerificationList();
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
            tabs: this.tabsList(language),
            MyRequestTab: this.myRequestTabList(language),
            selectedTab: languageTranslationData[language].All,
            MyRequestTabSelectedTab: languageTranslationData[language].MyRequests
        })
        this.handlePostapi()
        // Customizable Area End
    }

    // Customizable Area Start
    tabsList = (language: string) => [
        languageTranslationData[language].All,
        languageTranslationData[language].New,
        languageTranslationData[language].Archived
    ]

    myRequestTabList = (language: string) => [
        languageTranslationData[language].MyRequests,
        languageTranslationData[language].VerificationRequest
    ]

    handleAgreeButtonClick = () => {
        this.setState({ agreeModal: true });
    };

    handleModalConfirm = () => {
        this.setState({ agreeModal: false });
    };
    handleCancelButtonClick = () => {
        this.setState({ cancelModal: true });
    };

    handleModalBlockConfirm = () => {
        this.setState({ cancelModal: false });
    };

    handleChangeTab = (tab: MyRequestsOrVerificationRequest) => {
        this.setState({ MyRequestTabSelectedTab: tab })
    }

    handleChangeFilterTab = (tab: AllOrNewOrArchived) => {
        this.setState({ selectedTab: tab })
    }

    getRequestVerificationList = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.verificationRequestListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.listRequestVerificationEndPoint
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleFirstModalOpen = () => {
        this.setState({ isFirstModalOpen: true });
    };

    handleFirstModalClose = () => {
        this.setState({ isFirstModalOpen: false });
    };

    handleSecondModalOpen = () => {
        this.setState({ isFirstModalOpen: false, isSecondModalOpen: true });
    };

    handleSecondModalClose = () => {
        this.setState({ isSecondModalOpen: false });
    };

    handleThirdModalOpen = () => {
        this.setState({ isSecondModalOpen: false, isThirdModalOpen: true });
    };
    handleThirdModalClose = () => {
        this.setState({ isThirdModalOpen: false });
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "pending" || value === "due") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected" || value === "past due") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };
    handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({ dot: event.currentTarget });
    };

    handleClose = () => {
        this.setState({ dot: null });
    };
  async  handleNavigation(route: string) {
        await setStorageData("selectedRequest", this.state.selectedRequest)
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    onLogout = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };
    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.onLogout();
        } else{
            this.handleNavigation(route);
        }
    };
    
    handleNavigateForUserList = (userListId: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "UserRequest");
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), userListId)
        this.send(message);
      } 

    handlePostapi = async () => {
        const jsonData = {
            data: {
                attributes: {
                    details: this.state.additionalDetails,
                    registration_number: this.state.registrationNumber,
                    full_name: this.state.entityName,
                    verification_type: "Not specified",
                    status: "pending",
                    provider_id: "1117"
                }
            }
        };

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")

        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.tablePostApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.RequestEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(jsonData)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ isSubmit: true });
    }

    handleInputChange = (field:string, value: string) => {
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      };
      
    formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: '2-digit',
        }).format(date);
      };

    
    handleUserData = (event: React.MouseEvent<SVGSVGElement>, id: number) => {
        this.setState({selectedRequest: id })
    }

    countStatuses = (data: any[] | undefined) => {
        if (!Array.isArray(data)) {
            return {};
        }
        return data.reduce(
          (acc, item) => {
            const status = item.attributes.status;
            if (status) {
              acc[status] = (acc[status] || 0) + 1;
            }
            return acc;
          },
          {} as Record<string, number>
        );
      };

    handleFilterForVerification = async () => {
        const header = {
            "Content-Type": configJSON.loginApiContentType,
            token: await getStorageData("accessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.FilterRequestListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/request_verifications?query[status]=${this.state.status}&query[from]=${this.state.fromDate}&query[to]=${this.state.toDate}`
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            search: event.target.value
        })
    }
    handlePaginateData = ( newPage: number) => {
        this.setState({ currentPage: newPage });
      };
    
    handleRowsPerPageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
          rowsPerPage: parseInt(event.target.value, 10),
          currentPage: 0,
        });
      };
    // Customizable Area End
}
